$border-radius-sm: 0.5em;
$border-radius: 0.65em;
$border-radius-lg: 0.75em;

$enable-gradients: true;

$font-family-sans-serif: Noto, sans-serif;

$input-bg: #f2f6fa;
$input-border-color: #e2e6ea;

$primary: #64217a;

@import "~bootstrap/scss/bootstrap";

body {
  background-image: url("images/bg-main.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
}

.chat-message {
  border-radius: 1em;
  &--from-me {
    background: lighten($primary, 10%);
    border-top-right-radius: 0.25em;
    color: white;
  }

  &--from-them {
    background: lighten($primary, 60%);
    border-top-left-radius: 0.25em;
    color: black;
  }

  p:last-child {
    margin-bottom: 0 !important;
  }

  img {
    max-width: 256px;
    width: 100%;
    height: auto;
  }
}

.email-item:hover,
.meeting-item:hover {
  background: #efefef;
  cursor: pointer;
}

.follow-up-questions {
  width: 100%;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  .btn-toolbar {
    min-width: 1000px;
  }
}

.img-inline {
  height: 1em;
}

.img-grayscale {
  filter: grayscale(100%);
}

.link-label {
  display: inline-block;
  max-width: 128px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
